body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.RaLayout-content {
  margin-top: 32px;
}

.RaCreateButton-root {
  padding: 0 10px !important;
}

.RaLabeled-label {
  font-size: 13px !important;
  margin-bottom: 0 !important;
}

.RaSimpleShowLayout-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px !important;
}

.RaBulkActionsToolbar-toolbar {
  min-height: 48px !important;
  transform: translateY(-48px) !important;
  display: flex !important;
  align-items: center !important;
}

.RaBulkActionsToolbar-collapsed {
  min-height: 0 !important;
  transform: translateY(0) !important;
}

.RaToolbar-defaultToolbar {
  padding-top: 12px;
  padding-right: 16px;
  padding-left: 16px;
  max-width: 1200px;
  width: 100%;
  justify-content: flex-end !important;
}

.RaMenu-closed {
  width: 45px !important;
}

.tiptapContent span {
  /* white-space: nowrap !important; */
}

.tiptapContent .ProseMirror {
  max-width: 1200px;
  width: 100%;
  min-height: 600px;
  padding: 4px;
}

.ProseMirror {
  max-width: 1200px;
  width: 100%;
  min-height: 40px;
}

.RaTab-row {
  margin-bottom: 8px !important;
}
.RaList-actions {
  margin-bottom: 8px !important;
}

#description ul {
  list-style-type: none;
  padding-left: 0;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

#description ul li {
  position: relative;
  padding-left: 20px;
  line-height: 16px;
}

#description ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  background-image: url("shared/static/img/check_circle.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

#description ul li ul {
  list-style-type: none;
  padding-left: 0;
  line-height: 120%;
  gap: 4px;
  margin-top: 4px;
}

#description li ul li {
  position: relative;
  padding-left: 8px;
}

#description ul li ul li::before {
  content: "-";
  position: absolute;
  left: 0;
  top: -4px;
  width: auto;
  height: auto;
  background-image: none;
  font-size: 16px;
  line-height: 120%;
}

.sales-filter {
  width: "100%";
  padding-left: 32px;
  padding-right: 32px;
}
