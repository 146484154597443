.container {
  padding: calc(var(--g) + var(--b));
  --_g: #0000 25%, var(--c) 0;
  background:
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) var(--_i, 200%)
      0 /200% var(--_i, var(--b)) no-repeat,
    conic-gradient(at bottom var(--b) left var(--b), var(--_g)) 0 var(--_i, 200%) /
      var(--_i, var(--b)) 200% no-repeat;
  transition:
    0.3s,
    background-position 0.3s 0.3s;
}
.container:hover {
  --_i: 100%;
  transition:
    0.3s,
    background-size 0.3s 0.3s;
}

.hover {
  --_i: 100%;
  transition:
    0.3s,
    background-size 0.3s 0.3s;
}
